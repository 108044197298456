import React, { useEffect, useRef, useState } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import webGLFluidEnhanced from 'webgl-fluid-enhanced';
import feature1 from '../assets/images/features/1.jpg'
import feature2 from '../assets/images/features/2.jpg'
import feature3 from '../assets/images/features/3.jpg'
import feature4 from '../assets/images/features/4.jpg'
import feature5 from '../assets/images/features/5.jpg'
import feature6 from '../assets/images/features/6.jpg'
import feature7 from '../assets/images/features/7.jpg'
import feature8 from '../assets/images/features/8.jpg'
import feature9 from '../assets/images/features/9.jpg'
import Architecture from '../assets/videos/architecture.mp4'
import Platform from '../assets/images/platform.png'
import Facebook from '../assets/icons/facebook.svg'
import Instagram from '../assets/icons/instagram.svg'
import Linkedin from '../assets/icons/linkedin.svg'
import Telegram from '../assets/icons/telegram.svg'
import Twitter from '../assets/icons/twitter.svg'
import Youtube from '../assets/icons/youtube.svg'
import SelfCustody from '../assets/icons/self-custody.svg'
import WideMarketAccess from '../assets/icons/wide-market-access.svg'
import InstantRewards from '../assets/icons/instant-rewards.svg'
import EasyOnboarding from '../assets/icons/easy-onboarding.svg'
import LowFees from '../assets/icons/low-fees.svg'
import Tabs from "./Faq/Tabs";
import Application from "./Application/Application"
import axios from 'axios';
import { BACKEND_URL } from '../config';
import logo from '../assets/logo.svg';
import { NavLink } from 'react-router-dom';
const Landing = () => {
    const canvasRef = useRef(null);
    useEffect(() => {
        webGLFluidEnhanced.simulation(canvasRef.current, {
            SIM_RESOLUTION: 128,
            DYE_RESOLUTION: 512,
            CAPTURE_RESOLUTION: 200,
            DENSITY_DISSIPATION: 10,
            VELOCITY_DISSIPATION: 0.2,
            PRESSURE: 0.05,
            PRESSURE_ITERATIONS: 10,
            SPLAT_AMOUNT: 5,
            SPLAT_RADIUS: 0.25,
            SPLAT_FORCE: 12000,
            SHADING: true,
            COLOR_UPDATE_SPEED: 10,
            COLOR_PALETTE: ['#ff0000', '#ffd100', '#42ff00', '#00ffe5', '#002eff', '#ff00fa'],
            BRIGHTNESS: 0.15,
            SUNRAYS_WEIGHT: 2,
        });
    }, []);
    const [openAccordion, setOpenAccordion] = useState(null);
    const handleHover = (index) => {
        if (index !== openAccordion) {
            setOpenAccordion(index);
        } else {
            setOpenAccordion(null);
        }
    };

    const tabData = [
        { label: "MBD HybriDEX" },
        { label: "Trading on MBD HybriDEX" },
        { label: "Security on MBD HybriDEX" },
    ];
    const [coinData, setCoinData] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [indexNumber, setIndexNumber] = useState(0);
    useEffect(() => {
        getCoinData(page,perPage);
    }, []);
    const getCoinData = async (page,perPage) => {
        axios.get(`${BACKEND_URL}/coinmarketcap/cryptocurrency/listings?page=${page}&perPage=${perPage}`)
        .then((response) => {
            if(response.data.status){
                setCoinData(response.data.data.data);
                console.log(response.data )
                setTotalPage(Math.ceil(response.data.data.status.total_count / perPage));
            }else{
                setCoinData([]);
                setTotalPage(Math.ceil(0 / perPage));
            }
        })
        .catch((err) => {});
    }
    //console.log('coinData',coinData.data)
    const paginationHandler = (e, i) => {
        e.preventDefault();
        if (i != page) {
          setPage(i);
          setIndexNumber(perPage * i - perPage);
          getCoinData(i,perPage);
        }
    };
    const [open, setOpen] = React.useState(false);
    if(open===true) {
        document.body.classList.add('modalOpen'); 
        //console.log('open',open)
    }else {
        document.body.classList.remove('modalOpen'); 
        //console.log('open',open)
    }

    const [isVisible, setIsVisible] = useState(false);

    const toggleComingSoon = () => {
        setIsVisible(!isVisible);
    };
    
    return (
        <>
            <ReactFullpage
                //fullpage options
                licenseKey = {'YOUR_KEY_HERE'}
                scrollingSpeed = {700} /* Options here */
                keyboardScrolling = {true}
                navigation = {true}
                navigationPosition = {'left'}
                //anchors = {['0','1','2','3','4','5','6','7','8','9','10']}
                recordHistory = {false}
                // lockAnchors = {true}
                navigationTooltips = {['HybriDex', 'Overview','Features', 'Chart','Architecture', 'FAQS','Application']}
                showActiveTooltip = {true}
                credits = {false}
                css3 = {false}
                fixedElements = {'#header'}
                //scrollBar = {false}
                touchSensitivity = {5}
                verticalCentered = {true}
                scrollOverflow = {true}
                //scrollOverflowReset = {true}
                scrollOverflowMacStyle = {true}
                fitToSection = {true}
                dragAndMove = {false}
                sectionSelector = {'.section'}
                easingcss3 = {'ease'}
                //responsiveWidth = {900}
                responsiveSlides = {true}
                observer = {false}
                //normalScrollElements = {'._5'}
                afterLoad={(origin , destination, direction, isResponsive) => {
                    //console.log(destination, 'destination')
                    // if (destination.item.classList.contains('fourth') || destination.isLast) {
                    if (destination.index >= 3) {
                        document.body.classList.add('circleThird');
                        return
                    } else {
                        document.body.classList.remove('circleThird');
                    }
                    }}
                render={({ state, fullpageApi }) => {
                return (
                    <>
                    <ReactFullpage.Wrapper>
                    <div id="header" className={`max-[576px]:px-8 max-[576px]:py-6 py-[2rem] px-[4rem] flex items-center fixed top-0 right-0 left-0 z-[5] bg-gradient-to-t from-[rgba(0,0,0,0)] to-[#000000]`}>
                        <nav className='flex flex-wrap items-center justify-between mx-auto flex-1'>
                            <NavLink to="/" className="flex items-center space-x-3 rtl:space-x-reverse max-[576px]:w-16 max-[576px]:table max-[576px]:mx-auto">
                                <img src={logo} className='max-w-[60px] md:max-w-[80px] lg:max-w-[100px]'/>
                            </NavLink>
                            {/* <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden dark:text-gray-400" aria-controls="navbar-default" aria-expanded="false">
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                    <path stroke="currentColor" d="M1 1h15M1 7h15M1 13h15"/>
                                </svg>
                            </button> */}
                            <div className="hidden w-full md:block md:w-auto ml-[5.25rem]">
                                <ul className="flex flex-col p-4 md:p-0 mt-4 border md:flex-row md:space-x-14 rtl:space-x-reverse md:mt-0 md:border-0 font-manifold text-base tracking-[.1em]">
                                    <li>
                                        <a href="https://hybridex.app/" target='_blank' className="block py-2 text-white" aria-current="page">Swap</a>
                                    </li>
                                    <li>
                                        <a onClick={toggleComingSoon} className="block py-2 text-white cursor-pointer" aria-current="page">Staking</a>
                                    </li>
                                    <li>
                                        <a onClick={toggleComingSoon} className="block py-2 text-white cursor-pointer" aria-current="page">Rewards</a>
                                    </li>
                                    <li>
                                        {/* <a href="https://thorchain.org/ecosystem" target='_blank' className="block py-2 text-white" aria-current="page" data-menuanchor='section3'>Ecosystem</a> */}
                                        <a onClick={() => fullpageApi.moveTo(7)}  className="block py-2 text-white cursor-pointer" aria-current="page" >Ecosystem</a>
                                    </li>
                                    <li className='dropdown'>
                                        {/* <a href="https://thorchain.org/ecosystem" target='_blank' className="block py-2 text-white" aria-current="page" data-menuanchor='section3'>Ecosystem</a> */}
                                        <a  className="block py-2 text-white cursor-pointer" aria-current="page" >Exchange</a>
                                        <ul className='dropdown-menu'>
                                            <li><a href='https://app.uniswap.org/explore/tokens/ethereum/0xaaf449bf8a33a32575c31ba8cbb90612dd95acfa' target='_blank'>Uniswap</a></li>
                                            <li><a href='https://www.mexc.com/login?inviteCode=1caSw' target='_blank'>MEXC</a></li>
                                            {/* <li><a href='https://hybridex.co/' target='_blank'>HybriDEX</a></li> */}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <a href="https://hybridex.app/" target='_blank' className='border border-white ml-auto hidden md:inline-block py-[1.15rem] px-12 font-pjlight'>Launch App</a>
                        </nav>
                    </div>
                    {/* {isVisible ? 'Hide Div' : 'Show Div'} */}
                    {isVisible && (
                        <div className='modal'>
                            <div className='absolute top-1 right-8 text-[3rem] cursor-pointer' onClick={toggleComingSoon}>&times;</div>
                            <div className='modal-inner'>
                                <h1 className='font-azonix'>Coming Soon!</h1>
                            </div>
                        </div>
                    )}
                        <div className="section _1 max-[576px]:!justify-normal">
                            <h1 className='
                                banner-title
                                text-[9rem]
                                leading-[9.5rem]
                                uppercase
                                font-azonix
                                relative
                                inline-block
                                pointer-events-none
                                z-10
                                max-[576px]:text-[2.5rem] max-[576px]:leading-[2.8rem] max-[576px]:mt-[12vh] max-[576px]:text-center max-[576px]:mx-auto max-[576px]:table'>
                                <span className='block'>MBD</span>
                                <span className='block'>HybriDex</span>
                            </h1>
                            <h3 className='relative z-10 font-pjRegular leftAnimation text-center md:text-left pointer-events-none'>Revolutionizing Cross-Chain Trading</h3>
                            <div className='divider h-[4px] bg-white border-none my-8 max-[576px]:mx-auto relative pointer-events-none z-10'></div>
                            <div className='max-[576px]:flex max-[576px]:justify-center inline-flex'>
                                <a href="" className='learnmore text-base tracking-[.3em] uppercase flex items-center font-pjBold relative z-10'>
                                    Learn More
                                    <span className="ml-6">
                                        <svg width="65" height="48" viewBox="0 0 65 48" fill="rgba(5, 0, 255, 0)" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="17.5" y="0.5" width="47" height="47" stroke="#0500FF"></rect>
                                            <path className="tip" d="M37.9299 17.9999L43.9999 24.0699L37.9299 30.1399" stroke="white"></path>
                                            <path className="cane" d="M1 24L43 24" stroke="white"></path>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                            <div className='z-10 text-[#5a4bff] absolute flex flex-col right-16 top-2/4 -translate-y-1/2 gap-y-5 max-[576px]:hidden [&_a]:flex [&_a]:items-center [&_a]:justify-center'>
                                <a href="https://www.facebook.com/MBDFinancials" className='w-6 h-6 one'><img src={Facebook} className='w-3'/></a>
                                <a href="https://twitter.com/MBDFinancials" className='w-6 h-6 two'><img src={Twitter} className='w-5'/></a>
                                <a href='https://www.youtube.com/channel/UCju8ObUSidjgEvevlXc9pVg' className='w-6 h-6 three'><img src={Youtube} className='w-5'/></a>
                                <a href='https://www.instagram.com/mbdfinancials/' className='w-6 h-6 four'><img src={Instagram} className='w-5'/></a>
                                <a href='https://t.me/MBDFinancialsVerification' className='w-6 h-6 five'><img src={Telegram} className='w-5'/></a>
                                <a href='https://www.linkedin.com/company/mbdfinancials/' className='w-6 h-6 six'><img src={Linkedin} className='w-5'/></a>
                            </div>
                            <div className='message pl-[1.5rem] max-w-[21.8125rem] leading-[1.375rem] ml-auto absolute right-16 bottom-16 max-[576px]:right-auto z-10 pointer-events-none'>
                                <p>Unlock seamless cross-chain trading with HybriDEX. Enjoy gasless limit orders, zero slippage, and unlimited market depth, along with guaranteed rates, low fees, rapid settlement, and secure native token trading.</p>
                            </div>
                            <canvas ref={canvasRef}/>
                        </div>
                        <div className="section _2">
                            <div className="flex max-[576px]:pt-10 max-[576px]:flex-col">
                                <div className='w-[30rem] max-[576px]:w-full max-[576px]:mb-5'>
                                    <p className="one text-lg font-pjMedium leading-none mt-10 max-[576px]:mt-0">Trading</p>
                                    <h2 className="two text-[3.375rem] max-[576px]:text-[2.375rem] font-azonix mt-4 leading-none gradientText inline-block">The Way It Should Be</h2>
                                    <p className='three text-[1.375rem] max-[576px]:text-[1rem] max-[576px]:leading-none leading-[1.625rem] font-azonix mt-[2.9375rem] max-[576px]:mt-8'>Enhanced Trading Experience</p>
                                    <div className="four text-sm leading-6 mt-11 font-pjlight opacity-[.8] hidden"></div>
                                </div>
                                <div className='px-[3.5rem] py-[5rem] bg-[hsla(0,0%,100%,.06)] ml-auto one w-[32rem] max-[576px]:w-full relative z-10 gap-10 flex flex-col [&_.heading]:font-pjMedium  [&_.heading]:text-lg  [&_.heading]:flex  [&_.heading]:items-center [&_.text]:mt-1.5 [&_.text]:pl-9 max-[576px]:p-6 max-[576px]:gap-5'>
                                    <div onMouseEnter={() => handleHover(1)} className={`item group  ${openAccordion === 1 ? "open" : ""}`}>
                                        <h2 className={`heading ${openAccordion === 1 ? "gradientText2" : ""}`}><img src={SelfCustody} className='mr-4 h-5'/> Self-Custody</h2>
                                        <div className='text hidden group-[.open]:block'>Keep full control of your funds, ensuring maximum security.</div>
                                    </div>
                                    <div onMouseEnter={() => handleHover(2)} className={`item group  ${openAccordion === 2 ? "open" : ""}`}>
                                        <h2 className={`heading ${openAccordion === 2 ? "gradientText2" : ""}`}><img src={WideMarketAccess} className='mr-4 h-5'/> Wide Market Access</h2>
                                        <div className='text hidden group-[.open]:block'>Access a diverse range of top trading pairs with deep liquidity, with new pairs regularly listed.</div>
                                    </div>
                                    <div onMouseEnter={() => handleHover(3)} className={`item group  ${openAccordion === 3 ? "open" : ""}`}>
                                        <h2 className={`heading ${openAccordion === 3 ? "gradientText2" : ""}`}><img src={InstantRewards} className='mr-4 h-5'/> Instant Rewards</h2>
                                        <div className='text hidden group-[.open]:block'>Earn rewards instantly with each trade you execute on our platform.</div>
                                    </div>
                                    <div onMouseEnter={() => handleHover(4)} className={`item group  ${openAccordion === 4 ? "open" : ""}`}>
                                        <h2 className={`heading ${openAccordion === 4 ? "gradientText2" : ""}`}><img src={EasyOnboarding} className='mr-4 h-5'/> Easy Onboarding</h2>
                                        <div className='text hidden group-[.open]:block'>Start trading quickly using your existing crypto wallet with a simple onboarding process.</div>
                                    </div>
                                    <div onMouseEnter={() => handleHover(5)} className={`item group  ${openAccordion === 5 ? "open" : ""}`}>
                                        <h2 className={`heading ${openAccordion === 5 ? "gradientText2" : ""}`}><img src={LowFees} className='mr-4 h-5'/> Low Fees</h2>
                                        <div className='text hidden group-[.open]:block'>Benefit from a competitive fee structure, allowing you to trade efficiently without high costs.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section _3">
                            <div className='flex items-center max-[576px]:block relative z-10'>
                                <div className='z-10 relative flex flex-col items-start'>
                                    <h2 className='two text-[3.375rem] font-azonix leading-none mt-4 gradientText inline-block max-[576px]:ml-7 max-[576px]:text-[2.375rem]'>SEAMLESS & <br/>EFFICIENT</h2>
                                    <div className='pl-12 my-12 three'>
                                        <div className='text-[1.375rem] font-pjMedium flex items-center before:absolute before:left-0 before:w-7 before:h-1 before:bg-gradient-to-r from-[#004fee] to-[#ee4492] leading-normal'>At HybriDEX</div>
                                        <div className='text-[.875rem] max-w-[22rem] mt-4 font-pjlight opacity-[.8] leading-normal'>Our mission is to transform DeFi by providing secure, intelligent, and efficient trading solutions tailored for both individual and institutional traders. Our platform seamlessly connects fragmented liquidity pools, offering a superior trading experience.</div>
                                    </div>
                                    <a href="https://hybridex.app/" target='_blank' className='learnmore text-base tracking-[.3em] uppercase flex items-center font-pjBold'>
                                        Launch App
                                        <span className="ml-6">
                                            <svg width="65" height="48" viewBox="0 0 65 48" fill="rgba(5, 0, 255, 0)" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="17.5" y="0.5" width="47" height="47" stroke="#0500FF"></rect>
                                                <path className="tip" d="M37.9299 17.9999L43.9999 24.0699L37.9299 30.1399" stroke="white"></path>
                                                <path className="cane" d="M1 24L43 24" stroke="white"></path>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                                <div className='ml-auto w-[40rem] relative'>
                                    <div className="slide">
                                        <div className='ml-auto max-[576px]:hidden max-[576px]:flex-nowrap max-[576px]:overflow-x-auto max-[576px]:gap-x-9'>
                                            <div className='flex items-center max-[576px]:block max-[576px]:min-w-[75%] one'>
                                                <div className='w-[20rem] py-8 px-12 max-[576px]:h-48'>
                                                    <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                                        Seamless Cross-Chain Trading
                                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>1</span>
                                                    </h4>
                                                    <p className='text-sm leading-5 font-pjlight mt-6'>Enjoy effortless trading across multiple blockchains, including Ethereum, BNB Chain, Polygon, Arbitrum, and Avalanche.</p>
                                                </div>
                                                <div className='w-[20rem]'>
                                                    <img src={feature1} className='w-full'/>
                                                </div>
                                            </div>
                                            <div className='flex items-center max-[576px]:block max-[576px]:min-w-[75%] two'>
                                                <div className='w-[20rem] py-8 px-12 max-[576px]:h-48 order-1'>
                                                    <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                                        Instant Settlement
                                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>2</span>
                                                    </h4>
                                                    <p className='text-sm leading-5 font-pjlight mt-6'>Experience near-instant trade settlement times, reducing wait periods and increasing efficiency.</p>
                                                </div>
                                                <div className='w-[20rem]'>
                                                    <img src={feature2} className='w-full'/>
                                                </div>
                                            </div>
                                            <div className='flex items-center max-[576px]:block max-[576px]:min-w-[75%] two'>
                                                <div className='w-[20rem] py-8 px-12 max-[576px]:h-48'>
                                                    <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                                        Gasless Limit Orders
                                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>3</span>
                                                    </h4>
                                                    <p className='text-sm leading-5 font-pjlight mt-6'>Place limit orders without incurring upfront gas fees, with tokens only deducted upon guaranteed execution.</p>
                                                </div>
                                                <div className='w-[20rem]'>
                                                    <img src={feature3} className='w-full'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className='ml-auto max-[576px]:hidden max-[576px]:flex-nowrap max-[576px]:overflow-x-auto max-[576px]:gap-x-9'>
                                            <div className='flex items-center max-[576px]:block max-[576px]:min-w-[75%] one'>
                                                <div className='w-[20rem] py-8 px-12 max-[576px]:h-48'>
                                                    <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                                        Zero Slippage
                                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>4</span>
                                                    </h4>
                                                    <p className='text-sm leading-5 font-pjlight mt-6'>Trade confidently with zero slippage on any order size, ensuring you get exactly what you expect.</p>
                                                </div>
                                                <div className='w-[20rem]'>
                                                    <img src={feature4} className='w-full'/>
                                                </div>
                                            </div>
                                            <div className='flex items-center max-[576px]:block max-[576px]:min-w-[75%] two'>
                                                <div className='w-[20rem] py-8 px-12 max-[576px]:h-48 order-1'>
                                                    <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                                        Native Token Transfers
                                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>5</span>
                                                    </h4>
                                                    <p className='text-sm leading-5 font-pjlight mt-6'>Conduct trades directly with native tokens, eliminating the risks associated with wrapped assets.</p>
                                                </div>
                                                <div className='w-[20rem]'>
                                                    <img src={feature5} className='w-full'/>
                                                </div>
                                            </div>
                                            <div className='flex items-center max-[576px]:block max-[576px]:min-w-[75%] two'>
                                                <div className='w-[20rem] py-8 px-12 max-[576px]:h-48'>
                                                    <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                                        High Scalability
                                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>6</span>
                                                    </h4>
                                                    <p className='text-sm leading-5 font-pjlight mt-6'>Handle any trading volume with our scalable infrastructure designed for high performance.</p>
                                                </div>
                                                <div className='w-[20rem]'>
                                                    <img src={feature6} className='w-full'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slide">
                                        <div className='ml-auto max-[576px]:hidden max-[576px]:flex-nowrap max-[576px]:overflow-x-auto max-[576px]:gap-x-9'>
                                            <div className='flex items-center max-[576px]:block max-[576px]:min-w-[75%] one'>
                                                <div className='w-[20rem] py-8 px-12 max-[576px]:h-48'>
                                                    <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                                        Secure and Non-Custodial
                                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>7</span>
                                                    </h4>
                                                    <p className='text-sm leading-5 font-pjlight mt-6'>Ensure the safety of your assets with our fully non-custodial platform, reducing risks associated with liquidity pools.</p>
                                                </div>
                                                <div className='w-[20rem]'>
                                                    <img src={feature7} className='w-full'/>
                                                </div>
                                            </div>
                                            <div className='flex items-center max-[576px]:block max-[576px]:min-w-[75%] two'>
                                                <div className='w-[20rem] py-8 px-12 max-[576px]:h-48 order-1'>
                                                    <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                                        AI-Enhanced Trading
                                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>8</span>
                                                    </h4>
                                                    <p className='text-sm leading-5 font-pjlight mt-6'>Benefit from AI-driven insights and strategies to optimize your trading and staking activities.</p>
                                                </div>
                                                <div className='w-[20rem]'>
                                                    <img src={feature8} className='w-full'/>
                                                </div>
                                            </div>
                                            <div className='flex items-center max-[576px]:block max-[576px]:min-w-[75%] two'>
                                                <div className='w-[20rem] py-8 px-12 max-[576px]:h-48'>
                                                    <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                                        Premium Analytics (Coming Soon)
                                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>9</span>
                                                    </h4>
                                                    <p className='text-sm leading-5 font-pjlight mt-6'>Gain access to advanced crypto insights with our upcoming launch of premium analytics. Make informed decisions with in-depth data and real-time market analysis.</p>
                                                </div>
                                                <div className='w-[20rem]'>
                                                    <img src={feature9} className='w-full'/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section _3 !hidden max-[576px]:!flex">
                            <div className='ml-auto 2xl:mt-8 2xl:py-16 max-[576px]:flex max-[576px]:flex-nowrap max-[576px]:overflow-x-auto max-[576px]:gap-x-9 relative z-10'>
                                <div className='flex flex-col items-center max-[576px]:min-w-[75%] one'>
                                    <div className='min-[577px]:w-[20rem] py-8 flex-1'>
                                        <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                            Seamless Cross-Chain Trading
                                            <span className='text-[2.5rem] font-azonix ml-[20px]'>1</span>
                                        </h4>
                                        <p className='text-sm leading-5 font-pjlight mt-6'>
                                            Enjoy effortless trading across multiple blockchains, including Ethereum, BNB Chain, Polygon, Arbitrum, and Avalanche.
                                        </p>
                                    </div>
                                    <div className='min-[577px]w-[20rem]'>
                                        <img src={feature1} className='w-full' />
                                    </div>
                                </div>
                                <div className='flex flex-col items-center max-[576px]:min-w-[75%] two'>
                                    <div className='min-[577px]w-[20rem] py-8 flex-1'>
                                        <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                        Instant Settlement
                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>
                                            2
                                        </span>
                                        </h4>
                                        <p className='text-sm leading-5 font-pjlight mt-6'>
                                            Experience near-instant trade settlement times, reducing wait periods and increasing efficiency.
                                        </p>
                                    </div>
                                    <div className='min-[577px]w-[20rem]'>
                                        <img src={feature2} className='w-full' />
                                    </div>
                                </div>
                                <div className='flex flex-col items-center max-[576px]:min-w-[75%] two'>
                                    <div className='min-[577px]w-[20rem] py-8 flex-1'>
                                        <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                        Gasless Limit Orders
                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>
                                            3
                                        </span>
                                        </h4>
                                        <p className='text-sm leading-5 font-pjlight mt-6'>
                                            Place limit orders without incurring upfront gas fees, with tokens only deducted upon guaranteed execution
                                        </p>
                                    </div>
                                    <div className='min-[577px]w-[20rem]'>
                                        <img src={feature3} className='w-full' />
                                    </div>
                                </div>
                                <div className='flex flex-col items-center max-[576px]:min-w-[75%] two'>
                                    <div className='min-[577px]w-[20rem] py-8 flex-1'>
                                        <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                        Zero Slippage
                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>
                                            4
                                        </span>
                                        </h4>
                                        <p className='text-sm leading-5 font-pjlight mt-6'>
                                        Trade confidently with zero slippage on any order size, ensuring you get
                                        exactly what you expect.
                                        </p>
                                    </div>
                                    <div className='min-[577px]w-[20rem]'>
                                        <img src={feature4} className='w-full' />
                                    </div>
                                </div>
                                <div className='flex flex-col items-center max-[576px]:min-w-[75%] two'>
                                    <div className='min-[577px]w-[20rem] py-8 flex-1'>
                                        <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                        Native Token Transfers
                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>
                                            5
                                        </span>
                                        </h4>
                                        <p className='text-sm leading-5 font-pjlight mt-6'>
                                        Conduct trades directly with native tokens, eliminating the risks associated
                                        with wrapped assets.
                                        </p>
                                    </div>
                                    <div className='min-[577px]w-[20rem]'>
                                        <img src={feature5} className='w-full' />
                                    </div>
                                </div>
                                <div className='flex flex-col items-center max-[576px]:min-w-[75%] two'>
                                    <div className='min-[577px]w-[20rem] py-8 flex-1'>
                                        <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                        High Scalability
                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>
                                            6
                                        </span>
                                        </h4>
                                        <p className='text-sm leading-5 font-pjlight mt-6'>
                                        Handle any trading volume with our scalable infrastructure designed for
                                        high performance.
                                        </p>
                                    </div>
                                    <div className='min-[577px]w-[20rem]'>
                                        <img src={feature6} className='w-full' />
                                    </div>
                                </div>
                                <div className='flex flex-col items-center max-[576px]:min-w-[75%] two'>
                                    <div className='min-[577px]w-[20rem] py-8 flex-1'>
                                        <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                        Secure and Non-Custodial
                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>
                                            7
                                        </span>
                                        </h4>
                                        <p className='text-sm leading-5 font-pjlight mt-6'>
                                        Ensure the safety of your assets with our fully non-custodial platform,
                                        reducing risks associated with liquidity pools.
                                        </p>
                                    </div>
                                    <div className='min-[577px]w-[20rem]'>
                                        <img src={feature7} className='w-full' />
                                    </div>
                                </div>
                                <div className='flex flex-col items-center max-[576px]:min-w-[75%] two'>
                                    <div className='min-[577px]w-[20rem] py-8 flex-1'>
                                        <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                        AI-Enhanced Trading
                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>
                                            8
                                        </span>
                                        </h4>
                                        <p className='text-sm leading-5 font-pjlight mt-6'>
                                            Benefit from AI-driven insights and strategies to optimize your trading and staking activities.
                                        </p>
                                    </div>
                                    <div className='min-[577px]w-[20rem]'>
                                        <img src={feature8} className='w-full' />
                                    </div>
                                </div>
                                <div className='flex flex-col items-center max-[576px]:min-w-[75%] two'>
                                    <div className='min-[577px]w-[20rem] py-8 flex-1'>
                                        <h4 className='text-lg font-pjBold relative leading-tight flex items-center justify-between'>
                                        Premium Analytics (Coming Soon)
                                        <span className='text-[2.5rem] font-azonix ml-[20px]'>
                                            9
                                        </span>
                                        </h4>
                                        <p className='text-sm leading-5 font-pjlight mt-6'>
                                            Gain access to advanced crypto insights with our upcoming launch of premium analytics. Make informed decisions with in-depth data and real-time market analysis.
                                        </p>
                                    </div>
                                    <div className='min-[577px]w-[20rem]'>
                                        <img src={feature9} className='w-full' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section _4">
                            <div className="flex flex-col items-center justify-center text-center">
                                <p className="one text-lg font-pjMedium leading-none my-10">Market</p>
                                <h2 className="text-[3.375rem] max-[576px]:text-[2rem] max-[576px]:w-[calc(100%+2rem)] font-azonix leading-none gradientText inline-block relative z-10 one">Enhanced Trading <br/>Experience</h2>
                                <div className="five"><div className="divider h-[4px] w-[6.125rem] bg-white border-none my-16"></div></div>
                                <div className='max-[576px]:flex max-[576px]:justify-center mt-5 max-[576px]:mt-0'>
                                    <a onClick={() => {setOpen(true)}} className='learnmore text-base tracking-[.3em] uppercase flex items-center font-pjBold relative z-10 cursor-pointer'>
                                        View Stats
                                        <span className="ml-6">
                                            <svg width="65" height="48" viewBox="0 0 65 48" fill="rgba(5, 0, 255, 0)" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="17.5" y="0.5" width="47" height="47" stroke="#0500FF"></rect>
                                                <path className="tip" d="M37.9299 17.9999L43.9999 24.0699L37.9299 30.1399" stroke="white"></path>
                                                <path className="cane" d="M1 24L43 24" stroke="white"></path>
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <div className={open ? "marketModal" : 'marketModal hidden'}>
                                <span className='close absolute right-4 text-3xl cursor-pointer z-50 max-[576px]:top-2' onClick={() => {setOpen(false)}}>&times;</span>
                                <h2 className="text-[2rem] max-[576px]:text-[1.2rem] max-[576px]:w-[calc(100%+2rem)] font-pjBold leading-none text-white relative z-10 one mb-12 max-[576px]:mb-4">Market Stats</h2>
                                <div className='inner over'>
                                    <div className='overflow-auto'>
                                        <table className='text-xs table font-pjRegular w-full'>
                                            <thead className='text-sm [&_th]:border-b [&_th]:border-b-[rgba(255,255,255,.2)] [&_th]:pb-2 max-[576px]:[&_th]:px-5'>
                                                <tr>
                                                    <th className='w-[50px] max-[576px]:!px-1 max-[576px]:w-[30px]'><div className='max-w-[50px] min-w-[50px] max-[576px]:max-w-[30px] max-[576px]:min-w-[30px] text-center'>#</div></th>
                                                    <th align='left' className='pl-5'>Name</th>
                                                    <th align='right'>Price</th>
                                                    <th align='right'>1h %</th>
                                                    <th align='right'>24h %</th>
                                                    <th align='right'>7d %</th>
                                                    <th align='right'>Market Cap</th>
                                                    <th align='right'>Volume(24h)</th>
                                                    <th align='right' className='whitespace-nowrap'>Circulating Supply</th>
                                                </tr>
                                            </thead>
                                            <tbody className='[&_td]:!py-2 max-[576px]:[&_td]:px-5 max-[576px]:[&_td]:text-nowrap max-[576px]:[&_td]:!py-[.3rem]'>
                                                {coinData?.map((coin, index) => (
                                                    <tr key={index} className='border-b border-b-[rgba(255,255,255,.2)]'>
                                                        <td className='w-[50px] max-[576px]:!px-1 max-[576px]:w-[30px]'><div className='max-w-[50px] min-w-[50px] max-[576px]:max-w-[30px] max-[576px]:min-w-[30px] text-center'>{indexNumber + index + 1}</div></td>
                                                        <td align='left' className='pl-5 w-[200px] whitespace-nowrap'>{coin.name} <span style={{color:"#a19c9c"}}>{coin.symbol}</span></td>
                                                        <td  align='right'>${coin.quote.USD.price.toFixed(4)}</td>
                                                        <td align='right'><span className={(coin.quote.USD.percent_change_1h) < 0 ? 'text-[#f00]':'text-[#16c784]'}>{coin.quote.USD.percent_change_1h.toFixed(2)}%</span></td>
                                                        <td align='right'><span className={(coin.quote.USD.percent_change_24h) < 0 ? 'text-[#f00]':'text-[#16c784]'}>{coin.quote.USD.percent_change_24h.toFixed(2)}%</span></td>
                                                        <td align='right'><span className={(coin.quote.USD.percent_change_7d) < 0 ? 'text-[#f00]':'text-[#16c784]'}>{coin.quote.USD.percent_change_7d.toFixed(2)}%</span></td>
                                                        <td align='right'>${coin.quote.USD.market_cap.toLocaleString(undefined, {maximumFractionDigits:0})}</td>
                                                        <td><div className='flex flex-col justify-end items-end'>${coin.quote.USD.volume_24h.toLocaleString(undefined, {maximumFractionDigits:0})}<br/> <small>{coin.quote.USD.volume_change_24h.toLocaleString(undefined, {maximumFractionDigits:0})}</small></div></td>
                                                        <td align='right'>{coin.circulating_supply.toLocaleString(undefined, {maximumFractionDigits:0})} {coin.symbol}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="mt-4 px-2 relative z-50 max-[576px]:mt-2">
                                        {coinData && coinData.length > 0 && (
                                        <div className='flex justify-center'>
                                            <ul className="flex items-center mb-0 justify-content-end">
                                            <li
                                                className={`paginate_button page-item previous ${
                                                page <= 1 ? "disabled" : ""
                                                }`}
                                            >
                                                <a
                                                className="page-link disable-click px-1 mx-1 cursor-pointer"
                                                onClick={(e) => paginationHandler(e, page - 1)}
                                                >
                                                {/* <MdOutlineKeyboardArrowLeft /> */}
                                                {"<"}
                                                </a>
                                            </li>

                                            {totalPage &&
                                                [...Array(totalPage)].map((data, i) => {
                                                if (i <= page + 2 && i > page - 3) {
                                                    return (
                                                    <li
                                                        className={`paginate_button page-item ${
                                                        i + 1 == page ? "pageactive" : ""
                                                        }`}
                                                        key={"page" + i}
                                                    >
                                                        <a
                                                        aria-controls="customerManagment"
                                                        data-dt-idx="1"
                                                        tabIndex="0"
                                                        className="page-link px-2 cursor-pointer"
                                                        onClick={(e) => paginationHandler(e, i + 1)}
                                                        >
                                                        {i + 1}
                                                        </a>
                                                    </li>
                                                    );
                                                }
                                                })}
                                            <li
                                                className={`paginate_button page-item next  ${
                                                page >= totalPage ? "disabled" : ""
                                                }`}
                                                id="customerManagment_next"
                                                disabled={true}
                                            >
                                                <a
                                                href="#"
                                                aria-controls="customerManagment"
                                                data-dt-idx="2"
                                                tabIndex="0"
                                                className="page-link px-1 mx-1 cursor-pointer"
                                                onClick={(e) => paginationHandler(e, page + 1)}
                                                >
                                                {/* <MdOutlineKeyboardArrowRight /> */}
                                                {">"}
                                                </a>
                                            </li>
                                            </ul>
                                        </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section _5 !pb-0 [&_.fp-overflow]:h-[100vh] hybriDexCircle max-[576px]:!p-0">
                            <div className='min-[577px]:w-[51.25rem] relative mx-auto flex flex-col justify-start h-full'>
                                <div className='max-[576px]:px-8'>
                                    <p className="text-lg font-pjRegular leading-none mt-8 one max-[576px]:mt-28">Solution</p>
                                    <h3 className="mt-4 text-[3.375rem] max-[576px]:text-[2.375rem] font-azonix gradientText inline-block leading-none two">Architecture</h3>
                                </div>
                                <div className='wrapper relative flex flex-col justify-end flex-1 max-[576px]:flex-none'>
                                    <h1 className='absolute left-0 top-[2%] text-[4.25rem] max-[576px]:text-[2rem] leading-none font-pjMedium text-transparent text-stroke three max-[576px]:left-8'>Consistent Case<br/>HybriDEX</h1>
                                    <div className='w-[35vh] h-[35vh] max-[576px]:w-[22vh] max-[576px]:h-[22vh] mx-auto my-4 relative mix-blend-lighten'>
                                        <div className='blur-5 relative'>
                                            <video autoPlay data-autoplay muted loop playsInline webkit-playsinline className='w-full h-full four'>
                                                <source src={Architecture} type="video/mp4"></source>
                                            </video>
                                            <div className='absolute -left-2 top-[-3.5rem] inline-flex items-center justify-center flex-col text-base one'>
                                                <span className='font-azonix leading-tight w-[4vh] h-[4vh] flex items-center justify-center bg-gradient-to-r from-[#004fee] to-[#ee4492] bg-clip-text text-transparent mb-2 backdrop-filter-[44px] shadow-[inset_-10px_14px_20px_hsla(0,0%,100%,.08)] rounded-full bg-[hsla(0,0%,100%,.01)]'>S</span>
                                                <span className='font-pjRegular leading-none max-[576px]:text-sm'>Swap</span>
                                            </div>
                                            <div className='absolute -right-20 top-[-3.3rem] inline-flex items-center justify-center flex-col text-base two'>
                                                <span className='font-azonix leading-tight w-[4vh] h-[4vh] flex items-center justify-center bg-gradient-to-r from-[#004fee] to-[#ee4492] bg-clip-text text-transparent mb-2 backdrop-filter-[44px] shadow-[inset_-10px_14px_20px_hsla(0,0%,100%,.08)] rounded-full bg-[hsla(0,0%,100%,.01)]'>C</span>
                                                <span className='font-pjRegular leading-none max-[576px]:text-sm'>Crypto Analytics</span>
                                            </div>
                                            <div className='absolute -left-20 top-[5.5rem] max-[576px]:top-[2.5rem] inline-flex items-center justify-center flex-col text-base six'>
                                                <span className='font-azonix leading-tight w-[4vh] h-[4vh] flex items-center justify-center bg-gradient-to-r from-[#004fee] to-[#ee4492] bg-clip-text text-transparent mb-2 backdrop-filter-[44px] shadow-[inset_-10px_14px_20px_hsla(0,0%,100%,.08)] rounded-full bg-[hsla(0,0%,100%,.01)]'>S</span>
                                                <span className='font-pjRegular leading-none max-[576px]:text-sm'>Stake</span>
                                            </div>
                                            <div className='absolute -right-24 top-[6rem] max-[576px]:top-[3rem] inline-flex items-center justify-center flex-col text-base three max-[576px]:-right-[6rem]'>
                                                <span className='font-azonix leading-tight w-[4vh] h-[4vh] flex items-center justify-center bg-gradient-to-r from-[#004fee] to-[#ee4492] bg-clip-text text-transparent mb-2 backdrop-filter-[44px] shadow-[inset_-10px_14px_20px_hsla(0,0%,100%,.08)] rounded-full bg-[hsla(0,0%,100%,.01)]'>R</span>
                                                <span className='font-pjRegular leading-none max-[576px]:text-sm'>Rewards</span>
                                            </div>                                            
                                            <div className='absolute -left-14 top-[14.5rem] max-[576px]:top-[8rem] inline-flex items-center justify-center flex-col text-base five'>
                                                <span className='font-azonix leading-tight w-[4vh] h-[4vh] flex items-center justify-center bg-gradient-to-r from-[#004fee] to-[#ee4492] bg-clip-text text-transparent mb-2 backdrop-filter-[44px] shadow-[inset_-10px_14px_20px_hsla(0,0%,100%,.08)] rounded-full bg-[hsla(0,0%,100%,.01)]'>E</span>
                                                <span className='font-pjRegular leading-none max-[576px]:text-sm'>Ecosystem</span>
                                            </div>                                            
                                            <div className='absolute -right-[4.8rem] top-[14.5rem] max-[576px]:top-[8rem] inline-flex items-center justify-center flex-col text-base four'>
                                                <span className='font-azonix leading-tight w-[4vh] h-[4vh] flex items-center justify-center bg-gradient-to-r from-[#004fee] to-[#ee4492] bg-clip-text text-transparent mb-2 backdrop-filter-[44px] shadow-[inset_-10px_14px_20px_hsla(0,0%,100%,.08)] rounded-full bg-[hsla(0,0%,100%,.01)]'>L</span>
                                                <span className='font-pjRegular leading-none max-[576px]:text-sm'>Leaderboard</span>
                                            </div>
                                        </div>
                                        <div className='absolute inset-y-0 inset-x-0 flex items-center justify-center text-[2rem] max-[576px]:text-[1.25rem] font-azonix leading-none text-center six'>MBD<br/> HybriDex</div>
                                    </div>
                                    <div className='w-1/2 max-[576px]:w-3/5 mx-auto five'>
                                        <img src={Platform}/>
                                    </div>
                                    <div className='circles absolute -top-6 left-0 w-full h-full six'>
                                        <div className='circle1'></div>
                                        <div className='circle2'></div>
                                        <div className='circle3'></div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div className="section _8 [&>.fp-overflow]:!overflow-auto">
                            <Tabs tabs={tabData} />
                        </div>
                        <div className="section _9 max-[576px]:[&>.fp-overflow]:!overflow-auto">
                            <div className='max-[576px]:pt-5'>
                                <div className='mb-3'>
                                    <h3 className="text-[3.375rem] font-azonix gradientText inline-block leading-none max-[576px]:text-[2.375rem] two">Partnership Application</h3>
                                </div>
                                <div className='max-w-[50rem] three mb-4 max-[576px]:mb-6'>
                                    <p>Join the MBD HybriDEX ecosystem! Submit your project details and help us deliver unique DeFi solutions for financial freedom on a user-friendly platform for everyone.</p>
                                </div>
                                <Application/>
                            </div>                            
                        </div>
                    </ReactFullpage.Wrapper>
                    </>
                );
                }}
            />
        </>
    )
}

export default Landing